import AmitGridish from "../../assets/team_members/amitGridish.jpg";
import Ben from "../../assets/team_members/ben.jpg";
import Moran from "../../assets/team_members/moran.jpg";
import AmitKamisa from "../../assets/team_members/amitKamisa.jpg";
import Michael from "../../assets/team_members/michael.jpg";
import Esti from "../../assets/team_members/esti.jpg";
import Yitav from "../../assets/team_members/yitav.jpg";
import Barak from "../../assets/team_members/barak.jpg";
import Liat from "../../assets/team_members/limor.jpg";
import Limor from "../../assets/team_members/liat.jpg";
import Carmit from "../../assets/team_members/carmit.jpg";
import ShayB from "../../assets/team_members/SHAI.png";
import ShayA from "../../assets/team_members/shay.jpg";
import Hila from "../../assets/team_members/HILA.png";
import Ricki from "../../assets/team_members/Ricki.png";
import Shirly from "../../assets/team_members/Shirli.png";
import Efi from "../../assets/team_members/Efi.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const TeamMemberDictionary = {
  AmitGridish: (
    <LazyLoadImage effect="blur" className="profile-image" src={AmitGridish} alt="AmitGridish" />
  ),
  AmitKamisa: (
    <LazyLoadImage effect="blur" className="profile-image" src={AmitKamisa} alt="AmitKamisa" />
  ),
  Ben: <LazyLoadImage effect="blur" className="profile-image" src={Ben} alt="Ben" />,
  Moran: <LazyLoadImage effect="blur" className="profile-image" src={Moran} alt="Moran" />,
  Michael: <LazyLoadImage effect="blur" className="profile-image" src={Michael} alt="Michael" />,
  Esti: <LazyLoadImage effect="blur" className="profile-image" src={Esti} alt="Esti" />,
  Yitav: <LazyLoadImage effect="blur" className="profile-image" src={Yitav} alt="Yitav" />,
  Barak: <LazyLoadImage effect="blur" className="profile-image" src={Barak} alt="Barak" />,
  Liat: <LazyLoadImage effect="blur" className="profile-image" src={Liat} alt="Liat" />,
  Limor: <LazyLoadImage effect="blur" className="profile-image" src={Limor} alt="Limor" />,
  Carmit: <LazyLoadImage effect="blur" className="profile-image" src={Carmit} alt="Carmit" />,
  ShayA: <LazyLoadImage effect="blur" className="profile-image" src={ShayA} alt="Shay" />,
  ShayB: <LazyLoadImage effect="blur" className="profile-image" src={ShayB} alt="Shay" />,
  Hila: <LazyLoadImage effect="blur" className="profile-image" src={Hila} alt="Shay" />,
  Shirly: <LazyLoadImage effect="blur" className="profile-image" src={Shirly} alt="Shay" />,
  Ricki: <LazyLoadImage effect="blur" className="profile-image" src={Ricki} alt="Shay" />,
  Efi: <LazyLoadImage effect="blur" className="profile-image" src={Efi} alt="Efi" />
};

export const TeamMemberList = [
  {
    fullName: "עמית גרידיש",
    jobTitle: 'מנכ"ל',
    memberImageKey: "AmitGridish",
  },
  {
    fullName: "אפי גולד",
    jobTitle: 'משנה למנכ"ל',
    memberImageKey: "Efi",
  },
  {
    fullName: "עמית כמיסה",
    jobTitle: "מנהל גיוס והשמה",
    memberImageKey: "AmitKamisa",
  },
  {
    fullName: "בן גרידיש",
    jobTitle: "מנהל כספים ותפעול",
    memberImageKey: "Ben",
  },
  {
    fullName: "ייטב חדד",
    jobTitle: "מנהל הדרכה ופיתוח ארגוני",
    memberImageKey: "Yitav",
  },
  {
    fullName: "שירלי בן שמעון",
    jobTitle: "מנהלת פעילות מאוחדת",
    memberImageKey: "Shirly",
  },
  {
    fullName: "שי שוורץ",
    jobTitle: "מנהלת פעילות הוט",
    memberImageKey: "ShayA",
  },
  {
    fullName: "כרמית פיין",
    jobTitle: "מנהלת מוקד בני ברק",
    memberImageKey: "Carmit",
  },
  {
    fullName: "שי כהן",
    jobTitle: "מנהל מוקד הראל",
    memberImageKey: "ShayB",
  },
  {
    fullName: "הילה חביב",
    jobTitle: "מנהלת מוקד ירושלים",
    memberImageKey: "Hila",
  },
  {
    fullName: "ריקי אביטבול",
    jobTitle: "מנהלת מוקד אשדוד",
    memberImageKey: "Ricki",
  }
];
